@import '../scss/variables';
@import '../scss/mixins';

.templates-container {
  width: 100%;
  padding: $spacing-tiny-small;
}

.templates-create-new {
  margin-bottom: $spacing-normal;
}

.template-save-button {
  margin: $spacing-tiny-small $spacing-tiny-small $spacing-tiny-small 0;
  color: green;

  &:hover {
    color: $spleis-green;
  }
}

.template-create-label {
  margin-right: $spacing-tiny-small;
}

.template-create-input {
  margin-right: $spacing-tiny-small;
}

.list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: $spacing-normal;
    margin-top: $spacing-tiny-small;
}

.template {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: $spacing-tiny-small;

    &:nth-child(odd) {
        background-color: $opera-grey;
    }
}

.templateId {
    color: $t-bane-grey;
    margin-right: $spacing-tiny-small;
    min-width: 1rem;
}

.noTemplateTitle {
    font-style: italic ;
}