@import '../../scss/variables';

.projectAndReceiverInfo {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-small;
}

.projectUserLinks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $spacing-small-normal;
    margin: $spacing-tiny-small 0 $spacing-small;
    width: 100%;

    [class='user-link'] {
        border: 1px solid $vigeland-grey {
            radius: 5px;
        }
        color: inherit;
        margin-bottom: $spacing-small;
        max-width: 300px;
        padding: $spacing-tiny-small;

        &:hover {
            background-color: $slottet-beige;
            text-decoration: none;
        }
    }

    [class='user-link-title'] {
        color: inherit;
        padding-bottom: $spacing-tiny-small;
        opacity: 0.8;
    }

    [class='user-link-org'] {
        background-color: rgba($spleis-blue, 1);
        border: 1px solid $vigeland-grey {
            radius: 5px;
        }
        color: $white;
        margin-bottom: $spacing-small;
        max-width: 300px;
        padding: $spacing-small;

        &:hover {
            background-color: rgba($spleis-blue, 0.6);
            text-decoration: none;
        }
    }

    [class='user-empty'] {
        line-height: 1.3;
        margin: $spacing-tiny-small 0;
    }
}

.profileBox {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-small;
    margin-right: $spacing-small;
    max-width: 20rem;
}

.actions {
    display: flex;
    flex-direction: column;

    > * {
        margin-bottom: $spacing-tiny-small;
    }
}

.eventInfoBox {
    background-color: var(--tertiary-background-color);
    border-radius: $border-radius;
    line-height: 1.3;
    margin-bottom: $spacing-small;
    max-width: 300px;
    padding: $spacing-small;
}

.overrideOwnerName {
    margin-bottom: $spacing-small;
    position: relative;
}

.overrideOwnerNameTitle {
    font-weight: bold;
    margin-bottom: $spacing-tiny;
}

.overrideOwnerNameName {

}