@import '../scss/variables';

.keyword-meny {
    margin: $spacing-small 0;
}

.keyword-table {
    margin-bottom: 5rem;
}
.keyword-container {
    width: 100%;
}

.keyword-content {
    padding: $spacing-tiny-small;
}