@import '../scss/variables';

.partnerUrl {
  margin: 2rem 0;
}

.productOrderContainer {
  padding: $spacing-normal;
}

.vippsError {
  margin-top: $spacing-normal;
  color: $sykkelvei-red;
}

.salesUnitsForm {
  margin-bottom: $spacing-normal
}