.form {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
  gap: 10px;

  > label:not(:last-child) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > input {
      width: 100%;
    }
    > textarea {
      width: 100%;
    }
  }
}

.rewardImage {
  max-width: 100%;
}
