@import '../../scss/variables';

.removeRedMark {
  color: $lambda-grey;
}

.redMarkText {
  white-space: pre;
}

.redMarkInfo {
  margin: $spacing-small 0;
}

.reasonField {
  width: 100%;
  min-width: 300px;
  min-height: 200px;
}