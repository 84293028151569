@import '../scss/variables';

.modalWrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 19;
}

.modalContainer {
  background-color: $white;
  color: $t-bane-grey;
  padding: $spacing-normal;
  border-radius: $border-radius;
  margin: 1rem;
  max-width: 100vw;
  min-width: 400px;
}

.prizes {

}

.buttons {
  text-align: center;
  margin-top: $spacing-small;
}

.button {
  margin: $spacing-xs;
}

.csvButton {
  margin-right: $spacing-small;
}