@import '../scss/variables';

.banner {
    &-container {
        border: 1px solid currentColor {
            radius: 5px;
        }
        display: block;
        margin: $spacing-tiny-small;
        padding: $spacing-tiny-small;
        text-align: center;
        width: calc(100% - #{$spacing-tiny-small * 2});
    }

    &-title {
        color: inherit;
        margin: 0;
    }

    &-theme {
        &-info {
            color: $spleis-blue;
            background: transparent;
        }

        &-warning {
            color: $white;
            background-color: $oslohavn-orange;
        }

        &-error {
            color: $white;
            background-color: $sykkelvei-red;
        }
    }
}
