@import '../scss/variables';

.shortlinkMeny {
    margin: $spacing-small 0;
}

.shortlinkTable {
    margin-bottom: 5rem;
}
.shortlinkContainer {
    width: 100%;
}

.shortlinkContent {
    padding: $spacing-tiny-small;
}

.label {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: $spacing-xs;
    justify-content: space-between;
}

.moreThanOne {
    padding: $spacing-tiny-small;
}