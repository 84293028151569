@import '../scss/variables';

.projectVersionDiff {
    margin: 20px 0;
}

.projectVersionDiffLoader {
    position: absolute;
    z-index: 11;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.projectVersionDiffLoaderContainer {
    position: fixed;
    z-index: 10;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    background:rgba(0,0,0,0.6);
}

.projectVersionDiffLoaderText {
    color: white;
}

.approveButton {
    float: right;
}