@import '../../scss/variables';

.considerButton {
    margin-right: $spacing-xs;
}

.considered {
    background-color: $havnelageret-peach;
    border-radius: $border-radius;
    color: $barcode-black;
    font-size: $font-size-small;
    padding: $spacing-tiny;
    margin: $spacing-xs $spacing-xs $spacing-xs 0;
}
