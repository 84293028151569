@import '../scss/variables';

.kimh-container {
    margin: 0 1em 1em;
    padding: 1em;
}

.task-body.org-container {
    flex-direction: column;
    flex-wrap: wrap;
    gap: $spacing-small-normal;
}

.kimh-description {
    color: $t-bane-grey;
    margin-bottom: $spacing-tiny-small;
}

.bankAccounts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //grid-template-columns: 2fr 1fr;
    gap: $spacing-small-normal;
    width: 100%;
}

.bankAccount {
    flex: 1 1 60%;
}

.nifBankAccount {
    flex: 1 5 30%;
    min-width: 15rem;
}

.kimh-clubname {
    margin-bottom: 0;
}

.kimh-label{
    font-weight: bold;
    display: inline;
    flex: 0 0 12rem;
    margin-bottom: $spacing-xs;
}

.kimh-value {
    display: inline;
    flex: 1 0 15rem;
    margin-bottom: $spacing-xs;
}

.kimh-clubinfo {
    display: flex;
    max-width: 24rem;
    flex-wrap: wrap;
    margin-bottom: $spacing-tiny-small;
}

.kimh-change-account {
    margin-left: $spacing-tiny-small;
}

.kimh-button-container {
    margin: 1em 0;
    button {
        padding: 0.8em;
        transition: all 0.5s ease-in-out;
        background-color: white;
        border: 1px solid black;
        border-radius: $border-radius;
    }

    button:hover {
        background-color: #00ccff;
        color: lightyellow;
    }
}

.infoboks {
    padding: 1em;
    background: #f6f6f6;
    border-radius: $border-radius;
    p {
        padding: 5px;
    }
}

.status-select {
    background-color: $white;
    color: $spleis-blue;
    margin: 0 5px;
}

.status-select-desctiption {
    margin-top: 10px;
}

.org-sok-container {
    margin-top: $spacing-tiny-small;
    flex-basis: 100%;
}

.org-subscription-container {
    margin: $spacing-tiny-small 0 $spacing-small;
    flex-basis: 100%;
}

.org-knapperad {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    .btn {
        margin-left: 1rem;
    }
}

.org-contact-candidate {
    display: block;
    width: 100%;
}

.org-contact-candidate-body {
    display: flex;
    flex-direction: row;
}

.button-with-left-margin {
    margin-left: $spacing-tiny-small;
}

.nifData {
    background-color: $tryvann-blue;
}
.orgContacts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: $spacing-small-normal;
}

.contact-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-small;
    justify-content: flex-start;
}
