@import '../scss/variables';

.comments-wrapper {
    background-color: rgba($white, 0.7);
    word-wrap: break-word;
}

.comment-container a {
    color: $spleis-blue;
}

.add-comment-button {
    margin-right: $spacing-small;
}

.post-to-slack {
    input {
        margin-right: $spacing-xs;
    }
}

.comments-list {
    border-top: 1px solid $vigeland-grey;
    margin-top: $spacing-tiny-small;
    max-width: 100%;
    width: 40em;
}

.comments-input-container {
    display: block;
    padding: $spacing-xs 0;
}

.comments-input {
    display: block;
    margin-bottom: $spacing-small;
    max-width: 100%;
    height: 4em;
    transition: height 500ms;
    width: 100%;

    &:valid,
    &:focus {
        height: 130px;
    }
}

.comments-toggle {
    float: right;
    font-size: $font-size-normal;
}

.comment-wrapper {
    display: block;
    line-height: 1.5;
    padding: $spacing-tiny-small 0;
}

.comment-container {
    background-color: $tryvann-blue;
    border-radius: 10px;
    padding: $spacing-tiny-small;
    margin-right: $spacing-normal;

    &.comment-owner {
        background-color: $sofienbergparken-green;
        color: $barcode-black;
        box-shadow: 2px 1px 3px rgba(darken($lambda-grey, 10%), 0.5);
        margin-left: $spacing-normal;
        margin-right: 0;
    }
}

.comment-meta {
    color: $t-bane-grey;
    font-size: $font-size-small;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: $spacing-xs;
}

.comment-delete {
    color: $t-bane-grey;
    font-size: $font-size-small;
    text-align: right;
    margin: $spacing-xs 0;
}

.comment-body {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}
