@import '../scss/variables';

.mainContainer {
    max-width: 100vw;
    width: 100%;

    @media (min-width: $size-s) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 100%;
        overflow: hidden;
    }
}
