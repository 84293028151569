@import '../../scss/variables';

.presetWrapper {
  width: 100%;
  margin: $spacing-normal 0;
}

.titleWrapper {
  width: 100%;
  margin: $spacing-normal 0;
}

.messageWrapper {
  width: 100%;
  margin: $spacing-normal 0;
}

.preset {
  background-color: $white;
  border: 0 {
    bottom: 2px solid $toyenbadet-blue;
  }
  color: $oslofjorden-blue;
  font-size: $font-size-small;
  line-height: 1.5em;
  outline: none;
  display: block;
  width: 100%;
  margin-top: $spacing-tiny;
}

.title {
  width: 100%;
  margin-top: $spacing-tiny;
}

.message {
  width: 100%;
  height: 400px;
  resize: both;
  display: block;
}

.addTextButtons {
  display: flex;
  justify-content: center;
  gap: $spacing-small;
}