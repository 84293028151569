@import '../scss/variables';

.btn {
    border: 2px solid currentColor;
    border-radius: $border-radius;
    background-color: $white;
    color: $oslofjorden-blue;
    cursor: pointer;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    font-family: $rounded-font;
    margin: 0;
    padding: $spacing-xs $spacing-tiny-small;

    &:not(:disabled):hover {
        background-color: $oslofjorden-blue;
        color: $vigeland-grey;
        border-color: $oslofjorden-blue;
    }
}

.btn-cta {
    @extend .btn;
    background-color: $spleis-green;
    border-color: $spleis-green;
}

.btn-warning {
    @extend .btn;
    background-color: $sykkelvei-red;
    border-color: $sykkelvei-red;
    color: $vigeland-grey;
}

.btn-small {
    font-size: $font-size-small;
    padding: $spacing-xs;
}

.btn-text {
    background: none;
    border: 0;
    display: inline;
    font-size: inherit;
    font-weight: $font-weight-normal;
    padding: inherit;
    text-decoration: underline;

    &:hover {
        background: none;
        color: inherit;
        text-decoration: none;
    }
}
