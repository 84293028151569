@import '../scss/variables';
@import '../scss/colors';

.select {
  font-size: $font-size-small;
  margin: 0 $spacing-xs;
  width: 200px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  color: hsl(0, 0%, 50%);
  padding: 2px 8px;

  &.nonDefault {
    color: white;
    border-color: $bysykkel-blue;
    background-color: $bysykkel-blue;
    font-weight: bold;
  }
}