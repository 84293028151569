@import '../scss/variables';

.lottery-ticket-table-container {
  overflow: auto;
  width: 100%;
}

.lottery-ticket-table {
  max-width: 100%;
  overflow: auto;
  width: 100%;
}

.promoted-section {
  border: $oslofjorden-blue thin solid;
  padding: $spacing-tiny-small;
  margin: $spacing-tiny-small;
}

.promoted-container {
  width: 100%;
}

.promoted-content {
  padding: $spacing-tiny-small;
  width: 100%;
}

.promoted-input {
  margin-bottom: 1em;
}

.promoted-label {
  color: $oslofjorden-blue;
  margin-right: 1em;
}

.modalWrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 19;
}

.modalContainer {
  background-color: $white;
  color: $t-bane-grey;
  padding: $spacing-normal;
  border-radius: $border-radius;
  text-align: center;
  margin: 1rem;
  max-width: 100vw;
  min-width: 400px;

  .btn.cancel {
    background-color: transparent;

    &:hover {
      color: #ffffff;
      background-color: $oslofjorden-blue;
    }
  }
}
