@import 'scss/reset';
@import 'scss/fonts';
@import 'scss/variables';
@import "scss/rootProperties";
@import '@fortawesome/fontawesome-pro/scss/fontawesome';
@import '@fortawesome/fontawesome-pro/scss/brands';
@import '@fortawesome/fontawesome-pro/scss/regular';
@import '@fortawesome/fontawesome-pro/scss/solid';
@import '@fortawesome/fontawesome-pro/scss/light';
@import '@fortawesome/fontawesome-pro/scss/duotone';

html {
    box-sizing: border-box;
    font-size: 100%;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    background: $opera-grey;
    color: $barcode-black;
    font-family: $default-font;
    font-size: $font-size-normal;
}

h1,
h2,
h3,
h4,
h5 {
    color: $oslofjorden-blue;
    font-family: $rounded-font;
}

h1 {
    font-size: $font-size-huge;
    margin-bottom: 0.5em;
}

h2 {
    font-size: $font-size-large;
    margin-bottom: 0.5em;
}

h3 {
    font-size: $font-size-normal-large;
    margin-bottom: 0.5em;
}

h4 {
    font-size: $font-size-normal;
}

h5 {
    font-size: $font-size-small;
}

li {
    list-style-type: none;
}

a {
    color: $bysykkel-blue;
    text-decoration: none;

    &:hover {
        color: $spleis-blue;
        text-decoration: underline;
    }
}

input[readonly] {
    background-color: $opera-grey;
    border: 1px solid currentColor;
}

select {
    border: 1px solid $t-bane-grey;
}

button,
input[type='button'],
input[type='submit'] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid currentColor;
    border-radius: $border-radius;
    background: inherit;
    padding: $spacing-xs;
}

button,
textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: $white;
    font-family: inherit;
}

input[type='text'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='search'],
textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: $white;
    border: 0 {
        bottom: 2px solid $toyenbadet-blue;
    }
    box-shadow: none;
    color: $oslofjorden-blue;
    font-family: $default-font;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    line-height: 1.5em;
    outline: none;
    text-align: left;
    transition: border 500ms;

    &:focus {
        border-color: currentColor;
    }
}

.main-app {
    align-items: start;

    @media only screen and (min-width: $size-s) {
        display: flex;
        height: 100vh;
        flex-direction: column;
        overflow: hidden;
        width: 100vw;
    }
}
