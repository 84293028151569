@import '../scss/variables';

$tasks-width-small: 250px;
$tasks-width: 250px;
$task-list-background-color: $oslofjorden-blue;

.stats-content {
    padding: 0;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: auto;
}

.stats-list-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.stats-header {
    color: $white;
    margin-bottom: 0;
    margin-top: $spacing-small;
    text-align: center;
}

.stats-list {
    height: 100%;
    overflow: auto;
    position: relative;
    margin: 1em;

    &-title {
        color: $spleis-green;
        font-size: $font-size-normal-large;
        padding: $spacing-tiny-small 0;
        margin: 0;
        text-align: center;
    }

    color: $vigeland-grey;
}

.stats-list li {
    cursor: pointer;
}

.stats-task-list {
    padding-bottom: $spacing-small;
}

.stats-menu-item {
    color: $vigeland-grey;
    width: 100%;
    padding: $spacing-tiny-small;

    &:hover,
    &:focus {
        color: $white;
        text-decoration: none;
    }
}

.stats-menu-item-selected {
    background-color: $spleis-blue;
}
