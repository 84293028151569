@import '../scss/variables';

.functional-banner {
    &-container {
        border: 1px solid currentColor {
            radius: 5px;
        }
        display: block;
        margin: $spacing-tiny-small;
        padding: $spacing-tiny-small;
        text-align: left;
        width: calc(100% - #{$spacing-tiny-small * 2});
    }

    &-header {
        color: inherit;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    &-title {
        color: inherit;
        margin: 0;
    }

    &-theme {
        &-info {
            color: $spleis-blue;
            background: transparent;
        }

        &-warning {
            color: $white;
            background-color: $oslohavn-orange;
        }
    }
}
