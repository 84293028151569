@import '../scss/variables';

.reports-container {
    word-wrap: break-word;
}

.reports__information--unacknowledged {
    color: $t-bane-grey;
    line-height: 1.3;
    margin-bottom: $spacing-tiny-small;
}

.reports__information--acknowledged {
    color: $t-bane-grey;
    font-weight: $font-weight-thin;
}

.report-container a {
    color: $spleis-blue;
}

.reports-list {
    border-top: 1px solid $vigeland-grey;
    margin-top: $spacing-tiny-small;
    max-width: 100%;
}

.report-wrapper {
    display: block;
    line-height: 1.5;
    padding: $spacing-tiny-small 0;
}

.report-wrapper__acknowledged .report {
    background-color: rgba($havnelageret-peach, 0.5);
}

$reporter-color: lighten($oslohavn-orange, 10%);
.report {
    background-color: $havnelageret-peach;
    border-radius: 10px;
    padding: $spacing-tiny-small;

    &--is-reporter {
        border-color: $reporter-color;
        background-color: $reporter-color;

        @at-root {
            .report-wrapper__acknowledged & {
                background-color: rgba($reporter-color, 0.5);
            }
        }
    }
}

.report-meta {
    color: $t-bane-grey;
    font-size: $font-size-small;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: $spacing-xs;
}

.report__comment {
    border-bottom: 1px solid lighten($havnelageret-peach, 5%);
    padding-bottom: $spacing-xs;
    margin-bottom: $spacing-xs;

    @at-root {
        .report--is-reporter & {
            border-color: lighten($reporter-color, 5%);
        }
    }
}

.report__reporter,
.report__reported {
    font-size: $font-size-small;
}

.report__acknowledged--status {
    display: block;
    font-size: $font-size-small;
    font-weight: $font-weight-thin;
    opacity: 0.8;
}
