@import '../scss/variables';

.recipient-projectid-link {
  margin-bottom: $spacing-tiny;
}

.recipients-add-comment-wrapper {
  margin-bottom: $spacing-small;
}

.recipient-table-header-cell {
  text-decoration: underline;
  cursor: pointer;
}

.recipient-filter {
  margin-bottom: $spacing-small;
}

.recipient-filter-label {
  margin-left: $spacing-tiny;
}

.org-count {
  color: $jernbanetorget-grey;
  margin-bottom: $spacing-normal;
}