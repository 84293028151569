@import '../scss/variables';

.confirm-modal-wrapper {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 19;
    overflow-y: scroll;
}

.confirm-modal-container {
    background-color: $white;
    color: $t-bane-grey;
    padding: $spacing-normal;
    border-radius: $border-radius;
    text-align: center;
    margin: 1rem;
    max-width: 100vw;
    min-width: 400px;

    .btn.cancel {
        background-color: transparent;

        &:hover {
            color: #ffffff;
            background-color: $oslofjorden-blue;
        }
    }
}

.confirm-modal-heading {
    margin-bottom: $spacing-tiny-small;
}

.confirm-modal-buttons-container {
    margin-top: $spacing-small;
    & > * {
        margin: $spacing-tiny-small;
    }
}
