@import '../scss/variables';

.searchInput {
    margin-top: $spacing-tiny;
    margin-bottom: $spacing-tiny;
    margin-right: $spacing-tiny;
}

.searchButton {
    margin-right: $spacing-tiny;
}

.section {
    margin-bottom: $spacing-normal;
}

.list {
    list-style: circle;

    li {
        margin-left: $spacing-small-normal;
        list-style-type: circle;
    }
}