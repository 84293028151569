@import '~react-table/react-table.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '../scss/variables';

.accounting-main-container {
    max-width: 100vw;
    width: 100%;

    @media only screen and (min-width: $size-s) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 100%;
    }
    overflow: scroll;
}

$tasks-width-small: 250px;
$tasks-width: 250px;
$task-list-background-color: $spleis-blue;

.accounting-filter {
    padding: $spacing-xs;
    background-color: $tryvann-blue;
    margin-bottom: $spacing-small;

    input {
        margin: $spacing-xs;
    }
}

.accounting-table {
    padding: $spacing-small;
    display: flex;
    height: 100%;
    flex: 1;
    overflow: hidden;
}

.accounting-container {
    background-color: $task-list-background-color;
    display: none;
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0;
    width: 100%;

    @media (min-width: $size-s) {
        display: block;
        overflow: auto;
        position: relative;
        max-width: $tasks-width-small;
        width: 30%;
    }
    @media (min-width: $size-ml) {
        max-width: $tasks-width;
    }

    .selected {
        background-color: $oslofjorden-blue;
    }
}

.accounting-list-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.accounting-header {
    background-color: inherit;
    color: $white;
    padding: $spacing-tiny-small $spacing-normal;
    margin-bottom: $spacing-small;
    width: 100%;
}

.accounting-list {
    color: $vigeland-grey;
    height: 100%;
    overflow: auto;
    position: relative;

    &-title {
        color: $spleis-green;
        font-size: $font-size-normal-large;
        padding: $spacing-tiny-small 0;
        margin: 0;
        text-align: center;
    }
}

.accounting-table .ReactTable .rt-resizer {
    z-index: 0;
}

.accounting-menu-item {
    color: $vigeland-grey;
    cursor: pointer;
    display: block;
    padding: $spacing-tiny-small;

    &:hover,
    &:focus {
        color: $white;
        text-decoration: none;
    }
}

.accounting-menu-item-selected {
    background-color: $oslofjorden-blue;
}

.map-invoices-container {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    height: 100%;
}

.map-invoices-wrapper {
    height: 100%;
    overflow-x: auto;
}

.map-invoices-items {
    padding: 1em;
    overflow: scroll;
    height: 100%;
    width: 50%;
}

.accounting-table-wrapper {
    overflow: auto;
    width: 100%;
}

.fetch-sum-button {
    margin-bottom: $spacing-small;
}
.cell-align-right {
    text-align: right;
}