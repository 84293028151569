@import '../../scss/variables';

.changeForms {
    text-align: left;
    max-width: 100%;
    width: $page-width-x-small;
}

.changeForm {
    padding: $spacing-normal;
}

.recieverTitle {
    margin-bottom: $spacing-normal;
}

.searchWrapper {
    display: flex;
    flex-wrap: wrap;
}

.searchField {
    width: 100%;
}

.changeText {
    margin-right: $spacing-normal;
}

.formButton {
    flex-grow: 0;
    margin: 0;
    width: auto;
}
