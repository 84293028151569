
@import '_colors.scss';

$spacing-xs: 0.25rem; /* 4px */
$spacing-tiny: 0.5rem; /* 8px */
$spacing-tiny-small: 0.75rem; /* 12px */
$spacing-small: 1rem; /* 16px */
$spacing-small-normal: 1.5rem; /* 24px */
$spacing-normal: 2rem; /* 32px */
$spacing-normal-large: 3rem; /* 46px */
$spacing-large: 4rem; /* 64px */
$spacing-xl: 5rem; /* 80px */
$spacing-huge: 6rem; /* 96px */

$font-weight-thin: 300;
$font-weight-normal: 500;
$font-weight-bold: 700;

$font-size-tiny: 0.875rem; /*  14px  */
$font-size-small: 1rem; /*  16px  */
$font-size-normal: 1.125rem; /*  18px  */
$font-size-normal-large: 1.25rem; /*  20px  */
$font-size-large: 1.5rem; /*  24px  */
$font-size-large-huge: 1.75rem; /*  28px  */
$font-size-huge: 2rem; /*  32px  */
$font-size-title: 3rem; /*  46px  */

$default-font: 'Museo Sans';
$rounded-font: 'Museo Sans Rounded';

$size-xxxxs: 360px;
$size-xxxs: 470px;
$size-xxs: 530px;
$size-xs: 600px;
$size-s: 768px;
$size-sm: 992px;
$size-md: 1199px;
$size-ml: 1399px;

$page-width-x-small: 600px;
$page-width-small: 800px;
$page-width-small-normal: 1000px;
$page-width: 1100px;
$page-width-normal: 1200px;

$border-radius: 0.3rem;