@import '../scss/variables';

.taskMain {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    margin: 0 auto {
        bottom: $spacing-normal;
    }
    position: relative;
    padding: 0;
    width: 100%;

    @media (min-width: $size-xxs) {
        padding: 0 $spacing-normal;
    }

    @media (min-width: $size-md) {
        max-height: 100%;
        overflow: auto;
    }
}

.taskWrapper {
    max-width: $page-width;
    margin: 0 auto;
    width: 100%;
}

$details-width: 350px;

.taskContainer {
    grid-template-columns: 2fr;
    overflow: auto;
    position: relative;
    width: 100%;

    @media (min-width: $size-md) {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        grid-auto-flow: column;
        grid-template-rows: 100%;
        overflow: hidden;
        height: 100%;
    }
}

.taskDetail {
    background-color: $white;
    border-top: 1px solid $lambda-grey;
    position: relative;
    padding: $spacing-normal;

    &:empty {
        display: none;
    }

    @media (min-width: $size-md) {
        align-self: stretch;
        border: {
            top: 0;
            left: 1px solid $lambda-grey;
        }
        overflow: auto;
        min-width: $details-width;
        width: $details-width;
    }
}
