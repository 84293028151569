@import '../scss/variables';

.button {
    color: $white;
    margin-top: $spacing-tiny-small;
    text-decoration: underline;

    &:hover:not(:disabled), &:focus:not(:disabled) {
        color: $white;
        text-decoration: none;
    }
}
