.subprojects {
  padding: 1rem 0;
}

.project-element {
  display: block;
  margin-bottom: 0.5em;
  padding:0;
  line-height: 1em;
  width: 100%;
}

.project-id {
  margin-right: 0.6em;
}