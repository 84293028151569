
/* BLUES */
$oslofjorden-blue: #002776;
$spleis-blue: #005aa4;
$bysykkel-blue: #0071cd;
$akerselva-blue: #008ed2;
$toyenbadet-blue: #00ccff;
$tryvann-blue: #dff1f9;

/* GREYS */
$barcode-black: #262626;
$t-bane-grey: #676767;
$jernbanetorget-grey: #adadad;
$lambda-grey: #ccc;
$vigeland-grey: #eaeaea;
$opera-grey: #f4f4f4;
$white: #fff;

/* REDS */
$sykkelvei-red: #da3d00;
$oslohavn-orange: #ff9100;
$havnelageret-peach: #f3bbaa;

/* PURPLE */
$indigo: #a20076;
$frogner-pink: #c94096;

/* YELLOW-ISH */
$slottet-beige: #f8f5eb;

/* GREENS */
$sognsvann-green: #008a00;
$spleis-green: #00ff99;
$sofienbergparken-green: #e1f4e3;
