@import '../../scss/variables';


.modalWrapper {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 19;
}

.modalContainer {
    background-color: $white;
    padding: $spacing-normal;
    border-radius: $border-radius;
    margin: 1rem;
    max-width: $size-s;
    min-width: 400px;
}

.modalButtons {
    text-align: center;
    margin-top: $spacing-normal;
}

.modalButton {
    margin: $spacing-xs;
}

.modalContent {
    white-space:pre-line;
}