.project-element {
  display: block;
  margin-bottom: 0.5em;
  padding:0;
  line-height: 1em;
  width: 100%;
}

.project-list-section-title {
  cursor: pointer;
  margin-top: 1em;
  padding:0;
  line-height: 1em;
}

.project-list-section-title:hover {
  font-weight: bold;
}

.expanded {
  font-weight: bold;
}

.project-id {
  margin-right: 0.6em;
}