@import '../scss/variables';

.project-tags-loved {
    padding: $spacing-tiny-small 0;
}

.project-tags-add {
    margin: $spacing-tiny-small 0 0;
}

.project-tags-list {
    display: flex;
    flex-wrap: wrap;
    margin: $spacing-tiny-small 0;
    width: 100%;
}

.project-tag {
    background-color: $spleis-blue;
    border-radius: 7px;
    color: $white;
    cursor: pointer;
    display: inline-block;
    margin: $spacing-xs {
        left: 0;
    }
    max-width: 100%;
    overflow: hidden;
    padding: $spacing-xs;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.project-tags-input {
    position: relative;

    input {
        width: 100%;
    }
}

.project-tags-filters {
    background-color: $white;
    border: 1px solid $lambda-grey;
    border-radius: 0 0 $border-radius $border-radius;
    display: none;
    overflow: auto;
    padding: $spacing-xs 0;
    position: absolute;
    width: 100%;

    &:empty {
        display: none !important;
    }
}

.tags-filter {
    cursor: pointer;
    max-width: 100%;
    overflow: hidden;
    padding: $spacing-xs;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
        background-color: rgba($spleis-blue, 0.5);
        color: $white;
    }

    &:focus {
        background-color: $spleis-blue;
        color: $white;
        margin: 0;
        outline: none;
    }
}

.project-tags-input:focus-within {
    .project-tags-filters {
        display: block;
    }
}


@media (max-width: $size-xs) {
    .project-tags-input:hover {
        .project-tags-filters {
            display: block;
        }
    }
}