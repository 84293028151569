@import '../scss/variables';

$menu-inner-height: 35px;
.menu-main-container {
    align-self: start;
    width: 100%;
}

.menu-container {
    background-color: $white;
    border-bottom: 1px solid $lambda-grey;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: $spacing-tiny;
    grid-template-columns: 1fr;
    width: 100%;

    @media (min-width: $size-s) {
        flex-direction: row;
    }
}

.modalWrapper {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 19;
}

.modalContainer {
    background-color: $white;
    color: $t-bane-grey;
    padding: $spacing-normal;
    border-radius: $border-radius;
    max-width: 100vw;
    max-height: 100vh;
    position: fixed;
    inset: 0;
    width: fit-content;
    height: fit-content;
    margin: auto;
    overflow: scroll;
}

.todoConfigInfo {
    margin-bottom: $spacing-normal;
}

.todoConfigButtons {
    margin-top: $spacing-small;
}

.loggedInAs {
    align-items: center;
    background-color: $tryvann-blue;
    display: flex;
    font-size: $font-size-small;
    gap: $spacing-tiny-small;
    padding: $spacing-tiny;
    justify-content: flex-end;
}

.menu-logo-container {
    box-sizing: border-box;
    position: relative;
}

.menu-logo {
    display: block;
    height: 100%;
    margin: 0 auto;
    max-height: $menu-inner-height;
    max-width: 100%;
    padding: $spacing-xs;
}

.menu-navigation {
    display: flex;
    font-family: $rounded-font;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    flex-wrap: wrap;
    justify-content: center;
    gap: $spacing-tiny-small;
    padding: $spacing-tiny;

    @media (min-width: $size-s) {
        gap: $spacing-small;
        line-height: $menu-inner-height;
        padding: 0;

    }
}

.menu-navigation-item {
    padding: $spacing-xs;

    @media (min-width: $size-s) {
        height: $menu-inner-height;
        padding: 0;
    }
}

.menu-navigation-item-badge {
    color: white;
    background: red;
    padding: $spacing-xs $spacing-tiny;
    border-radius: $spacing-tiny;
}

.menu-navigation-item-link {
    color: $oslofjorden-blue;
    padding-bottom: $spacing-xs;
    text-decoration: none;
    position: relative;

    &:hover {
        border-bottom: 2px solid currentColor;
        text-decoration: none;
    }
}

.menu-overlay {
    display: none;
    position: absolute;
    background-color: $oslofjorden-blue;
    z-index: 1000;
    padding: $spacing-tiny-small;

    * {
        color: $white;

        &:hover {
            color: $white;
        }
    }
}

.menu-overlay-show {
    display: block;
}

.menu-search-form-container {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-small;
    justify-content: center;
}

.menu-search-input {
    margin-right: $spacing-xs;
    width: 10rem;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.menuAlert {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    min-height: 48px;
    padding: $spacing-small;
    position: relative;
    transition: all 500ms ease-in-out;
    width: 100%;
    z-index: 1;
}

.hidingAlert {
    height: 0;
    opacity: 0;
    border-top: 0;
    border-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.searchBox {
    font-size: $font-size-small;
    margin: 0 $spacing-xs;
    width: 200px;
}

.option {
    padding: 5px;
}

.selectedOption {
    color: $t-bane-grey;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.isSelected {
    background-color: $opera-grey;
    font-style: italic;
}

.isFocused {
    background-color: $tryvann-blue;
}

.projectOwner {
    color: $t-bane-grey;
}