@import '../scss/variables';

.container {
    max-width: 100%;
    margin-bottom: $spacing-small;
    background: $white;

    h2 {
        margin-bottom: 0;
    }
}

.titleButton {
    border: 0;
    border-radius: 0;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-tiny $spacing-small;
    text-align: left;
    width: 100%;
}

.title {
    &.isCollapsed {
        border-bottom: 2px solid var(--border-contrast-color);
        color: $jernbanetorget-grey;

        i {
            color: $jernbanetorget-grey;
        }
    }
}

.children {
    padding: 0 $spacing-small $spacing-small;
}