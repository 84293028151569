@import '../scss/variables';
@import '../scss/mixins';

$tasks-width-small: 250px;
$tasks-width: 300px;
$task-list-background-color: $spleis-blue;

.org-list-container {
  background-color: $task-list-background-color;
  display: none;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0;
  width: 100%;

  @media (min-width: $size-s) {
    display: block;
    overflow: auto;
    position: relative;
    max-width: $tasks-width-small;
    width: 30%;
  }
  @media (min-width: $size-ml) {
    max-width: $tasks-width;
  }

  .org-selected {
    background-color: $oslofjorden-blue;
  }
}

.org-select {
  background-color: $white;
  color: $spleis-blue;
  margin: $spacing-tiny-small 0;
  width: 100%;
}

.org-list-item {
  color: $vigeland-grey;
  display: flex;
  flex-direction: row;
  font-size: $font-size-small;
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: 1.2;
  padding: $spacing-small $spacing-small-normal;
  position: relative;

  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;
  }

  &:focus {
    background-color: rgba(lighten($oslofjorden-blue, 7%), 0.5);
  }
  &:hover {
    background-color: $oslofjorden-blue;
  }

  &-container {
    background: transparent;
  }

  &-id {
    color: $jernbanetorget-grey;
    padding-right: $spacing-xs;
    padding-bottom: $spacing-xs;
    width: 100%;
  }

  &-name {
    color: inherit;
    flex-basis: 70%;
    flex-grow: 2;
    margin-bottom: $spacing-xs;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50%;
  }

  &-time {
    align-self: flex-start;
    flex-shrink: 2;
    font-size: $font-size-tiny;
    font-weight: $font-weight-thin;
    min-width: 140px;
  }

  &-owner {
    font-size: $font-size-tiny;
    flex-basis: 70%;
    flex-grow: 2;
  }

  &-money {
    color: inherit;
    flex-basis: 30%;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: $font-size-tiny;
    text-align: right;
  }
}

.org-header {
  background-color: inherit;
  color: $white;
  padding: $spacing-tiny-small $spacing-small;
  margin-bottom: $spacing-small;
  width: 100%;
}

.org-list {
  height: 100%;
  overflow: auto;

  &-title {
    color: $spleis-green;
    font-size: $font-size-normal-large;
    padding: $spacing-tiny-small 0;
    margin: 0;
    text-align: center;
  }
}

.org-header-subtitle {
  color: $vigeland-grey;
  font-size: $font-size-small;
  text-align: right;
}

.org-list-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
