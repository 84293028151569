@import '../scss/variables';

.matchingGiftCreateForm {
    display: flex;
    align-items: center;
    flex-direction: column;
    & > * {
        margin: $spacing-tiny 0;
        width: 100%;
    }
}

.selected-matching-gift {
    background-color: $havnelageret-peach !important;
    font-weight: bold;
}