@import '../scss/variables';


.lottery-main-container {
  max-width: 100vw;
  width: 100%;

  @media only screen and (min-width: $size-s) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
  }
  overflow: scroll;
}

$tasks-width-small: 250px;
$tasks-width: 250px;
$task-list-background-color: $oslofjorden-blue;

.lottery-content {
  padding: 0;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.lottery-container {
  background-color: $task-list-background-color;
  display: none;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0;
  width: 100%;

  @media (min-width: $size-s) {
    display: block;
    overflow: auto;
    position: relative;
    max-width: $tasks-width-small;
    width: 30%;
  }
  @media (min-width: $size-ml) {
    max-width: $tasks-width;
  }

  .selected {
    background-color: $spleis-blue;
  }
}

.lottery-list-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.lottery-header {
  background-color: inherit;
  color: $white;
  padding: $spacing-tiny-small $spacing-small;
  margin-bottom: $spacing-small;
  width: 100%;
  font-size: 2em;
  text-align: center;
}

.lottery-list {
  height: 100%;
  overflow: auto;
  position: relative;
  margin: 1em;
  &-title {
    color: $spleis-green;
    font-size: $font-size-normal-large;
    padding: $spacing-tiny-small 0;
    margin: 0;
    text-align: center;
  }
  color:$vigeland-grey;
}

.lottery-list li {
  cursor: pointer;
}

.lottery-menu-item {
  color: $vigeland-grey;
  width: 100%;
  padding: $spacing-tiny-small;

  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;
  }
}

.lottery-menu-item-selected {
  background-color: $spleis-blue;
}
