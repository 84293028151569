@import '../scss/variables';

.mainContainer {
  margin: $spacing-tiny-small;
  overflow: auto;
}

.categoriesContainer {
  display: flex;
  flex-wrap: wrap;
}

.categoryContainer {
  height: 10rem;
  width: 20rem;
  margin: $spacing-tiny-small;
  padding: $spacing-tiny-small;
  flex-direction: row;
  border: $lambda-grey solid 1px;
  border-radius: $border-radius;
  background-color: $vigeland-grey;
}

.categoryTitle {
  height: 5rem;
  width: 100%;
}

.clickable {
  cursor: pointer;
}