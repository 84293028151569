@import '../scss/variables';

.button-link {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    border-radius: 0;
    color: $bysykkel-blue;
    padding: 0;
    line-height: inherit;
    text-decoration: none;

    &:hover:not(:disabled) {
        background-color: inherit;
        color: $bysykkel-blue;
        text-decoration: underline;
    }
    &:focus {
        border: none !important;
        background: transparent !important;
        text-decoration: none;
    }
}
