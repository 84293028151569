@import '../scss/variables';

$tasks-width-small: 250px;
$tasks-width: 300px;
$task-list-background-color: $spleis-blue;
.task-list-container {
    background-color: $task-list-background-color;
    display: none;
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0;
    width: 100%;

    @media (min-width: $size-s) {
        display: block;
        overflow: auto;
        position: relative;
        max-width: $tasks-width-small;
        width: 30%;
    }
    @media (min-width: $size-ml) {
        max-width: $tasks-width;
    }

    .task-selected {
        background-color: $oslofjorden-blue;
    }
}

.task-list-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.tasks-header {
    background-color: inherit;
    color: $white;
    padding: $spacing-tiny-small $spacing-tiny-small;
    margin-bottom: $spacing-small;
    width: 100%;
}

.task-list {
    height: 100%;
    overflow: auto;
    position: relative;
}

.tasks-header-subtitle {
    color: $vigeland-grey;
    font-size: $font-size-small;
    text-align: right;
}

.task-list-item {
    color: $vigeland-grey;
    display: block;
    font-size: $font-size-small;
    line-height: 1.2;
    padding: $spacing-small $spacing-small;
    position: relative;

    &:hover,
    &:focus {
        color: $white;
        text-decoration: none;
    }

    &:focus {
        background-color: rgba(lighten($oslofjorden-blue, 7%), 0.5);
    }
    &:hover {
        background-color: $oslofjorden-blue;
    }

    &-container {
        background: transparent;
    }

    &-id {
        color: $jernbanetorget-grey;
        padding-right: $spacing-xs;
        padding-bottom: $spacing-xs;
        width: 100%;
    }

    &-title {
        color: inherit;
        font-size: $font-size-small;
        margin-bottom: $spacing-tiny;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &-time {
        font-size: $font-size-tiny;
        font-weight: $font-weight-thin;
    }

    &-money {
        color: inherit;
        font-size: $font-size-tiny;

        &-collected {
            font-weight: bolder;
            color: $spleis-green;
        }

        &-none-collected {
            font-weight: bolder;
        }

        &-kr {
            color: $jernbanetorget-grey;
        }
    }

}
