
@import '../scss/variables';

.arrowIcon {
    color: inherit;
    font-size: $font-size-tiny;
    transition: transform 150ms ease-in-out;

    &.rotate {
        transform: rotate(180deg);
    }
}