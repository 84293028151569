@import '../scss/variables';
.subscription-table-container {
    overflow: auto;
    width: 100%;
    margin-bottom: $spacing-tiny-small;
}

.invoice-table {
    max-width: 100%;
    overflow: auto;
    width: 100%;
}

.invoice-table-amount {
    text-align: right;
    white-space: nowrap;
}
.invoice-transaction-input {
    display:block;
    margin-bottom: 0.5em;
}

.invoice-transaction-input > span {
    float: left;
    width: 9em;
}

.invoice-table-data {
    margin-bottom: $spacing-normal;
}

.subscription-info {
    display: grid;
    grid-template-columns: 200px 50%;
    margin: $spacing-tiny-small;
    gap: $spacing-xs;
}

.subscription-number {
    white-space: nowrap;
}

.subscription-label {
    font-weight: $font-weight-bold;
}

.invoice-email-form {
    display: grid;
    margin: $spacing-tiny-small;
    justify-items: start;
    gap: $spacing-tiny-small;
}

.subscription-type-item {
    padding: $spacing-tiny-small;
    cursor: pointer;
}

.subscription-type-item-selected {
    background: #005aa4;
    color: white;
}

.subscription-types-selection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
}

.modal-wrapper {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 0;
    display: flex;
    height: 400px;
    justify-content: center;

    width: 400px;
    z-index: 19;
}

.modal-container {
    background-color: $white;
    color: $t-bane-grey;
    padding: $spacing-normal;
    border-radius: $border-radius;
    text-align: center;
    margin: 1rem;
    max-width: 400px;
    min-width: 400px;

    .btn.cancel {
        background-color: transparent;

        &:hover {
            color: #ffffff;
            background-color: $oslofjorden-blue;
        }
    }
}

.modal-heading {
    margin-bottom: $spacing-tiny-small;
}

.modal-buttons-container {
    margin-top: $spacing-xs;
    & > * {
        margin: $spacing-tiny-small;
    }
}
.invoice-unpaid {
    background-color: $havnelageret-peach;
}