@import '../scss/variables';

.infoData {
    color: $barcode-black;
    line-height: 1.5;
    margin: $spacing-tiny-small 0;
    width: 100%;

    li {
        position: relative;
    }
}
.infoDataList {
    ~ .info-data-list {
        margin-top: $spacing-tiny-small;
    }
}

.infoDataTitle {
    font-weight: $font-weight-bold;
}

.infoDataSubtitle {
    color: $barcode-black;
    font-weight: $font-weight-bold;
    margin-bottom: $spacing-tiny-small;
}

.dataSublist {
    padding: 0 {
        left: $spacing-small;
    }
}

.infoLabel {
    padding-right: $spacing-xs;
}

.infoValue {
    display: inline-block;
    font-weight: $font-weight-bold;
    text-align: right;

    &:empty {
        &::before {
            color: $jernbanetorget-grey;
            content: '<Tom>';
            font-weight: $font-weight-thin;
            font-style: italic;
        }
    }
}
