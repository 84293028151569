
@import '../../scss/variables';

.optionsContainer {
    border-top: 1px solid var(--border-contrast-color);
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 100%;
    padding: $spacing-small-normal $spacing-small;
    width: 100%;
}

.options {
    display: flex;
    flex: 1 0 100%;
    flex-wrap: wrap;
    gap: $spacing-small;
    margin-top: $spacing-small;
    width: 100%;
}