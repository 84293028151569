@import '../scss/variables';

.tooltip-link-container {
    position: relative;
}

.tooltip-container {
    background-color: $white;
    border: 2px solid $vigeland-grey;
    display: none;
    position: absolute;
    left: calc(100% + #{$spacing-xs});
    padding: $spacing-xs;
    top: -5px;
    width: 250px;
    z-index: 2;

    &.show {
        display: block;
    }
}
