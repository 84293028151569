@import '../scss/variables';

.audits-container {
    margin-bottom: $spacing-normal;
    word-wrap: break-word;
}

.audit-container a {
    color: $spleis-blue;
}

.audits-filter {
    width: 100%;

    &__warning {
        color: $t-bane-grey;
        font-size: 0.8em;
        font-weight: $font-weight-thin;
        font-style: italic;
    }
}

.audits-list {
    border-top: 1px solid $vigeland-grey;
    margin-top: $spacing-tiny-small;
    max-width: 100%;
}

.audit-wrapper {
    display: block;
    font-size: $font-size-tiny;
    line-height: 1.5;
    padding: $spacing-tiny-small 0;
}

.audit-meta {
    color: $t-bane-grey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: $spacing-xs;
}

.audit-container {
    background-color: $slottet-beige;
    padding: $spacing-tiny-small;

    &.expandable {
        cursor: pointer;
        transition: background 0.2s ease-in-out;
        overflow: hidden;

        &:hover {
            background-color: $tryvann-blue;
        }
    }
}

.admin-audit + div {
    background-color: $tryvann-blue;
}

.admin-audit + div:hover {
    background-color: $sofienbergparken-green;
}
