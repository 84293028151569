@import '../scss/variables';

$facebook-color: #4267b2;

.userContainer {
    background-color: $white;
}

.loginInformation {
    margin-bottom: $spacing-small;
}

.loginInformationTitle {
    font-weight: $font-weight-bold;
    padding-bottom: $spacing-tiny-small;
}

.loginOptions {
}

.loginOption {
    background-color: $vigeland-grey;
    border-radius: $border-radius;
    color: $barcode-black;
    margin-right: $spacing-tiny-small;
    padding: $spacing-xs $spacing-tiny-small;
}

.verificationDate {
    font-style: oblique;
}
.verificationList {
    list-style: circle;
    margin-left: $spacing-small;
}

.verificationListItem {
    list-style-type: circle;
    text-align: left;

}

.userLink {
    border: 1px solid $vigeland-grey;
    border-radius: 5px;
    color: inherit;
    display: block;
    margin-bottom: $spacing-small;
    max-width: 300px;
    padding: $spacing-small;

    &:hover {
        background-color: $slottet-beige;
        text-decoration: none;
    }
}

.userList {
    display: flex;
    flex-wrap: wrap;
}

.phoneLinkButton {
    color: $bysykkel-blue;
    text-decoration: none;
    border: none;
    font-weight: $font-weight-bold;
    background: transparent;
}
