@import '../scss/variables';
.invoice-table-container {
    overflow: auto;
    width: 100%;
    margin-bottom: $spacing-normal;
}

.invoice-table {
    max-width: 100%;
    overflow: auto;
    width: 100%;
}

.invoice-table-amount {
    text-align: right;
    white-space: nowrap;
}
.invoice-transaction-input {
    display:block;
    margin-bottom: 0.5em;
}

.invoice-transaction-input > span {
    float: left;
    width: 9em;
}

.invoice-table-data {
    margin-bottom: $spacing-normal;
}

.add-transaction-container {
    margin-bottom: $spacing-normal;
}