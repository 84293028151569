@import '../scss/variables';

.section {
    margin-bottom: $spacing-normal;
}

.inputText {
    width: 100%;
}

.summary {
    white-space: pre-wrap;
    word-break: break-word;
}
