@import '../scss/variables';

.potential-income-section {
    margin-bottom: $spacing-normal;
}

.potential-income-table {
    max-width: 100%;
}

.potential-income-pricing {
    word-wrap: anywhere;
}