@import '../../scss/variables';

.container {
  text-align: left;
}

.label {
  margin-bottom: $spacing-tiny;
}

.label input[type="checkbox"] {
  margin-right: $spacing-tiny;
}
