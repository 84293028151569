@import '../scss/variables';

.task-header {
    background-color: $oslofjorden-blue;
    color: $white;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: $spacing-normal;
    text-align: center;
    width: 100%;

    a {
        color: inherit;

        &:hover {
            color: $toyenbadet-blue;
        }
    }

    &.faded {
        background-color: $lambda-grey;
    }
}

.task-title {
    color: $vigeland-grey;
    margin-bottom: 0.3em;
}

.task-actions {
    align-content: flex-start;
    background-color: var(--primary-background-color);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 0;
    justify-content: flex-start;
    gap: $spacing-small;
    padding: $spacing-tiny;
    width: 100%;

    &--split {
        justify-content: space-between;
    }
}

.task-action-button {
    margin: $spacing-xs $spacing-xs $spacing-xs 0;

    &.red {
        border-color: $sykkelvei-red;
        color: $sykkelvei-red;
    }
}

.task-body {
    background-color: $white;
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    flex-shrink: 0;
    flex-wrap: wrap;
    margin-bottom: $spacing-small;
    padding: $spacing-small;

    @media (min-width: $size-xxs) {
        padding: $spacing-normal;
    }
}
