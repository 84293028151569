@import '../scss/variables';
@import '../scss/mixins';

.projectTemplate {
    padding: $spacing-small-normal;
    width: 100%;
}

.template-save-button {
    margin: $spacing-small $spacing-small $spacing-small 0;
    color: green;

    &:hover {
        color: $spleis-green;
    }
}

.template-input-container {
    display: flex;
    flex-direction: column;
    padding: $spacing-tiny 0;
    width: 100%;
}

.upload-container {
    padding: $spacing-tiny 0;
}

.imageUrl {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: $spacing-tiny 0;
    white-space: nowrap;
    width: 100%;
}

.upload-button {
    border: 2px solid currentColor;
    border-radius: $border-radius;
    background-color: $white;
    color: $oslofjorden-blue;
    padding: $spacing-xs $spacing-tiny-small;
}

.upload-image {
    margin: $spacing-small-normal 0;
    width: 35rem;
}

.template-label {
    display: flex;
    flex-direction: column;
    max-width: 35rem;
    width: 100%;
}

.template-checkbox-label input {
    margin-right: 0.5rem;
}

.template-label-editor {
    max-width: 100%;
}

.template-label-image {
    max-width: 100%;
}

.template-button {
    margin-right: $spacing-tiny-small;
    z-index: 10;
    background-image: unset;
    background-color: white;
}

.duplicate-template-button {
    margin: $spacing-tiny-small 0;
}

.warning-button {
    color: $sykkelvei-red;
    margin-left: $spacing-normal;
}

.create-project-from-template-button {
    margin-left: $spacing-normal;
}

.deleted-reward {
    background-image: linear-gradient(to right, $t-bane-grey 50%, $jernbanetorget-grey 50%);
    background-size: 10px 10px;

    input,
    select,
    textarea {
        background-color: $t-bane-grey;
    }
}

.json-input, .jsonInput {
    flex-grow: 1;
    height: 30rem;
}

.updateProjectProfilesInfo {
    margin-bottom: $spacing-normal;
}

.template-input {
    flex-grow: 1;
    margin: $spacing-xs 0;
}

.template-input-image {
    width: 100%;
}

.template-image-edit-container {
    background-color: $opera-grey;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: $spacing-xs 0;
    padding: $spacing-tiny-small;
}

.template-image-upload-container {
    width:100%;
}
.template-image-container {
    max-height: 25vh;
    height: 100%;
    width: 100%;
}

.template-image {
    display: block;
    object-fit: contain;
    object-position: left;
    padding: $spacing-tiny-small 0;
    max-height: 25vh;
    height: 100%;
    width: 100%;
}

.template-image-loader {
    align-items: center;
    display: flex;
    font-size: $font-size-large;
    justify-content: center;
    height: 200px;
    max-height: 25vh;
}

.template-add-reward {
    margin-top: $spacing-small;
    margin-bottom: $spacing-normal;
}

.rewards {
    display: flex;
    flex-direction: column;
    padding: $spacing-small 0;
    gap: $spacing-normal;
    
    &:empty {
        display: none;
    }
}

.reward-input {
    border: 2px solid $lambda-grey;
    background-color: $opera-grey;
    border-radius: $border-radius;
    flex-grow: 1;
    max-width: 35rem;
    padding: $spacing-small-normal;
}
