@import '../../scss/variables';

.registerAccountingError {
    display: flex;
    justify-content: center;
    padding: $spacing-small;
    width: 100%;
}

.title {
    text-align: center;
}

.subtitle {
    padding: $spacing-xs;
}

.registerForm {
    background: $white;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    padding: $spacing-small;
    max-width: 30em;
    width: 100%;
}

.buttonLink {
    flex: 0;
    align-self: flex-end;
    width: auto;
}

.formSection {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-normal;
}

.formLabel {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: $spacing-xs;
    justify-content: space-between;

    &:focus-within {
        background-color: $opera-grey;
        border-bottom: 1px solid $lambda-grey;
    }
}

.numberField {
    border-bottom: 1px solid $lambda-grey;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: $spacing-tiny-small;

    > span {
        flex: 0 0 10em;
    }
}

.numberInputWrapper {
    input {
        background: transparent;
        border: 0;
        text-align: right;
    }
}

.descriptionField {
    flex-direction: column;
}

.descriptionInput {
    background: transparent;
    min-height: 4em;
    width: 100%;
}

.messageBoxContainer {
    margin: $spacing-normal 0;
}

.messageBox {
    background: $lambda-grey;
    padding: $spacing-tiny-small;
    border-radius: $border-radius;
}

.infoMessage {
    @extend .messageBox;
}

.errorMessage {
    @extend .messageBox;
    color: $white;
    background-color: $havnelageret-peach;
}
