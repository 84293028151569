@import '../scss/variables';

.transactionInfoWrapper {
    margin-bottom: $spacing-normal;
}

.transactionInfoElement {
    margin-top: $spacing-tiny-small;
}

.hiddenComment {
    text-decoration: line-through;
    color: gray;
}

.subprojectList {
    text-align: left;
}