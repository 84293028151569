@import '../scss/variables';

.stats-section {
    margin-bottom: $spacing-normal-large;
}

.stats-section-mini {
    margin-bottom: $spacing-small;
}

.stats-event-list {
    display: flex;
    flex-wrap: wrap;
    margin: $spacing-tiny-small 0;
    width: 100%;
}

.stats-event, .stats-organization {
    background-color: $spleis-blue;
    border-radius: 7px;
    color: $white;
    cursor: pointer;
    display: inline-block;
    margin: $spacing-xs {
        left: 0;
    }
    max-width: 100%;
    overflow: hidden;
    padding: $spacing-xs;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.stats-csv-button {
    margin-bottom: $spacing-tiny-small;
}

.stats-hits {
    margin-bottom: $spacing-small;
}

.stats-search-field {
    width: 100%;
}

.stats-bold {
    font-weight: bold;
}