@import '../scss/variables';

.profile-info {
    align-self: flex-start;
    background-color: $opera-grey;
    border: 1px solid $lambda-grey {
        radius: 0.5rem;
    }
    flex-grow: 1;
    font-size: $font-size-small;
    margin: $spacing-tiny-small 0;
    max-width: 30rem;
    padding: $spacing-small;
    text-align: left;

    &-label {
        font-weight: $font-weight-normal;
        margin-bottom: $spacing-tiny-small;
        width: 100%;
    }

    &-container {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    &-header {
        align-items: center;
        display: flex;
        font-size: $font-size-normal;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        white-space: normal;
    }

    &-image-container {
        display: flex;
        flex-shrink: 0;
        height: 50px;
        width: 50px;
    }

    &-image-wrapper {
        background-position: center center;
        background-size: cover;
        border-radius: 50%;
        flex: 1;
        overflow: hidden;
        position: relative;
        transition: 120ms transform;
        z-index: 1;


        &::before {
            background-image: url('https://www.spleis.no/public/images/default-profile-image.svg');
            background-position: center center;
            background-size: cover;
            content: '';
            display: block;
            opacity: 1;
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -1;
        }

        &:hover {
            transform: scale(1.3);
        }
    }

    &-image {
        max-width: 100%;
    }

    &-text-container {
        flex: 1 0 150px;
        padding: $spacing-tiny-small;
        text-align: left;
    }

    &-title {
        display: inline-block;
        line-height: 1.2;
        font-weight: $font-weight-bold;
        padding: $spacing-xs 0 {
            right: $spacing-tiny-small;
        }
    }

    &-subtitle {
        font-size: $font-size-small;
        opacity: 0.8;
        margin-bottom: $spacing-tiny;
    }

    &-theme-large {
        border: 0;
        font-size: $font-size-normal;
        max-width: 100%;

        .profile-info {
            &-image-container {
                height: 70px;
                width: 70px;
            }

            &-text-container {
                font-size: $font-size-large;
                margin-left: $spacing-small;
            }
        }
    }
}

.edit-button {
    display: inline-block;
    margin-left: $spacing-xs;
    vertical-align: bottom;
}

.start-edit-button {
    display: inline-block;
    margin-left: 2em;
    vertical-align: bottom;
}

.profile-info-with-edit-button {
    margin-bottom: $spacing-xs;
    display: block;
}

input.edit-inline {
    color: inherit;
    background-color: inherit;
    font-size: inherit;
    line-height: inherit;
}

.profile-warning {
    background-color: $sykkelvei-red;
}

.profile-info-invitation {
    color: $barcode-black;
    border-top: 1px solid $lambda-grey;
    margin: $spacing-tiny-small $spacing-tiny-small 0 $spacing-tiny-small;
    padding-top: $spacing-tiny-small;
    width: 100%;
}

.profile-info-warning {
    color: $sykkelvei-red;
    display: flex;
    flex-direction: column;
    border-top: 1px solid $lambda-grey;
    padding-top: $spacing-tiny-small;
    margin: $spacing-tiny-small $spacing-tiny-small 0 $spacing-tiny-small;
    font-weight: $font-weight-bold;
    gap: $spacing-xs;
    width: 100%;
}