@import '../scss/variables';
@import '../scss/mixins';

.project-header {
    background-color: $oslofjorden-blue;
    color: $vigeland-grey;
    padding: 0;
    position: relative;
}

a.short-link {
    color: $white;
    font-size: $font-size-normal-large;
    &:hover {
        color: $white;
    }
}

.template-link {
    font-size: $font-size-normal-large;
    margin: $spacing-tiny 0;
}

.blurred-image {
    background: url('https://www.spleis.no/public/images/default-project-image.svg') no-repeat center center;
    background-size: cover;
    filter: blur(10px);
    position: absolute;
    top: -5%;
    left: -5%;
    height: 120%;
    width: 120%;
}

.project-header-image-container {
    background-repeat: no-repeat;
    display: block;
    overflow: hidden;
    position: relative;
    max-width: 100%;

    @media (min-width: $size-xs) {
        padding: $spacing-normal 100px 0;
    }
}
.project-header-image {
    @include image-aspect-ratio(1200, 480);
    background-size: cover;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    display: block;
    height: 100%;
    width: 100%;
}

.project-title {
    color: $vigeland-grey;
    margin: 0 auto;
    max-width: 30em;
    padding: $spacing-small;
}

.project-subtitle {
    color: $vigeland-grey;
}

a.project-id {
    color: $spleis-green;

    &:hover {
        color: $spleis-green;
    }
}

.project-purpose-container {
    flex: 0 0 100%;
    line-height: 1.5;
    margin: $spacing-small 0;
}

.project-description-container {
    flex: 0 0 100%;
    line-height: 1.5;
    margin: $spacing-small 0;
}

.project-description {
    hyphens: auto;
    word-break: break-word;
    width: 100%;
    max-width: 40em;

    img {
        max-height: 90vh;
        max-width: 100%;
    }
}

.project-body {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-wrap: wrap;
    padding: 0;
}

.project-collapse-icon {
    min-width: 2rem;
    margin-right: 0.5rem;
    text-align: center;
    color: $akerselva-blue;
}

.edit-button {
    display: inline-block;
    margin-left: $spacing-xs;
    vertical-align: bottom;

    &.small {
        padding: 2px 6px;
    }
}

input.edit-inline {
    color: inherit;
    background-color: inherit;
    font-size: inherit;
    line-height: inherit;
}

.claims-container {
    width: 100%;
}

.warning {
    color: $sykkelvei-red;
    font-weight: $font-weight-bold;
    margin-bottom: $spacing-tiny-small;
}

.rewards-container {
    width: 100%;
}
.rewards-cell {
    max-width: 300px;
}
.project-table-data {
    padding-top: $spacing-small;
    margin-bottom: $spacing-normal;
    max-width: 100%;
    overflow-x: auto;
    width: 100%;
}

.copy-transaction-table-button {
    margin-left: 5px;
    cursor: pointer;
}

.table-positive-number {
    color: $sognsvann-green;
}

.table-negative-number {
    color: $sykkelvei-red;
}

.project-statistics {
    width: 100%;
}

.project-statistics-numbers {
    line-height: 1.5;
    padding: $spacing-tiny-small 0;
    font-size: $font-size-normal-large;
}

.project-collected-stats {
    font-size: $font-size-large;
}

.reported-warning {
    color: $sykkelvei-red;
    font-weight: $font-weight-bold;
}

.project__payout--unacknowledged {
    @extend .reported-warning;
    margin-bottom: $spacing-small;
}

.dangerous-button {
    color: $sykkelvei-red;
    border-color: currentColor;

    &:not(:disabled):hover {
        background-color: $sykkelvei-red;
        border-color: currentColor;
        color: $white;
    }
}

.project-flags {
    margin: 1rem;
}

.money-laundering-label {
    margin-right: $spacing-tiny-small;
}

.moneylaundering-header {
    margin-top: $spacing-small;
    margin-bottom: $spacing-small;
}

.money-laundering-field {
    display: grid;
    grid-template-columns: 70% 30%;
    margin-bottom: $spacing-tiny;
    margin-top: $spacing-tiny;
}

.money-laundering-table-label {
    width: 56rem;
    padding-left: 0;
}

.money-laundering-submit {
    margin: $spacing-tiny-small;
}

.money-laundering-status {
    font-weight: $font-weight-bold;
    text-decoration: underline;
}

.money-laundering-google-button {
    margin-right: $spacing-tiny;
}

.additional-money-laundering-fields {
     padding: $spacing-tiny;
     background-color: $opera-grey;
 }

.terrorisme-money-laundering-fields {
    padding: $spacing-tiny;
}

.strong {
    font-weight: $font-weight-bold;
}

.red {
    color: $sykkelvei-red;
}

.green {
    color: $sognsvann-green;
}

.gray {
    color: $t-bane-grey;
}

.flaggedKeywordInText {
    color: red;
}