
@import '_colors.scss';

:root {
    --oslofjorden-blue: #{$oslofjorden-blue};
    --spleis-blue: #{$spleis-blue};
    --bysykkel-blue: #{$bysykkel-blue};
    --akerselva-blue: #{$akerselva-blue};
    --toyenbadet-blue: #{$toyenbadet-blue};
    --tryvann-blue: #{$tryvann-blue};
    --barcode-black: #{$barcode-black};
    --t-bane-grey: #{$t-bane-grey};
    --jernbanetorget-grey: #{$jernbanetorget-grey};
    --lambda-grey: #{$lambda-grey};
    --vigeland-grey: #{$vigeland-grey};
    --opera-grey: #{$opera-grey};
    --white: #{$white};
    --sykkelvei-red: #{$sykkelvei-red};
    --oslohavn-orange: #{$oslohavn-orange};
    --havnelageret-peach: #{$havnelageret-peach};
    --indigo: #{$indigo};
    --frogner-pink: #{$frogner-pink};
    --slottet-beige: #{$slottet-beige};
    --sognsvann-green: #{$sognsvann-green};
    --spleis-green: #{$spleis-green};
    --sofienbergparken-green: #{$sofienbergparken-green};

    /* text color */
    --primary-text-color: #{$barcode-black};
    --secondary-text-color: #{$t-bane-grey};
    --tertiary-text-color: #{$oslofjorden-blue};
    --error-text-color: #{$sykkelvei-red};
    --link-text-color: #{$spleis-blue};
    --secondary-link-text-color: #{$bysykkel-blue};
    --title-color: #{$oslofjorden-blue};
    --text-on-contrast-background-color: #{$white};

    /* background colors */
    --primary-background-color: #{$white};
    --secondary-background-color: #{$opera-grey};
    --tertiary-background-color: #{$tryvann-blue};
    --fourth-background-color: #{$slottet-beige};
    --fifth-background-color: #{$sofienbergparken-green};
    --sixth-background-color: #{$havnelageret-peach};
    --current-background-color: #{$white};
    --contrast-background-color: #{$oslofjorden-blue};

    /* inputs */
    --input-background-color: #{$opera-grey};
    --input-border-color: #{$jernbanetorget-grey};
    --input-readonly-border-color: #{$vigeland-grey};
    --input-text-color: #{$barcode-black};

    /* other */
    --shadow-color: rgba(0, 0, 0, 0.25);
    --border-color: #{$vigeland-grey};
    --border-contrast-color: #{$lambda-grey};
    --border-hover-color: #{$jernbanetorget-grey};
    --border-focus-color: #{$toyenbadet-blue};
    --input-border-focus-color: #{$bysykkel-blue};

    /* buttons */

    /* button important */
    --button-important-background-color: #{$spleis-green};
    --button-important-border-color: #{$spleis-green};
    --button-important-hover-background-color: lighten(#{$spleis-green}, 30%);
    --button-important-text-color: #{$oslofjorden-blue};

    /* button primary */
    --button-primary-background-color: #{$bysykkel-blue};
    --button-primary-border-color: #{$bysykkel-blue};
    --button-primary-hover-background-color: #{$akerselva-blue};
    --button-primary-text-color: #{$white};

    /* button alternative */
    --button-alternative-background-color: #{$oslofjorden-blue};
    --button-alternative-border-color: #{$oslofjorden-blue};
    --button-alternative-hover-background-color: lighten(#{$oslofjorden-blue}, 10%);
    --button-alternative-text-color: #{$white};

    /* button secondary */
    --button-secondary-background-color: transparent;
    --button-secondary-hover-background-color: #{$white};
    --button-secondary-text-color: #{$oslofjorden-blue};
    --button-secondary-hover-text-color: lighten($oslofjorden-blue, 10%);
}
