@import '../../scss/variables';

.form {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  flex-direction: row;
}

.submitButton {
  margin-top: $spacing-tiny-small;
  margin-right: $spacing-tiny-small;
  width: 5rem;
}