@import '../../scss/variables';

.modalWrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 19;
}

.modalContainer {
  background-color: $white;
  color: $t-bane-grey;
  padding: $spacing-normal;
  border-radius: $border-radius;
  text-align: center;
  margin: 1rem;
  max-width: 100vw;
  min-width: 400px;

  .btn.cancel {
    background-color: transparent;

    &:hover {
      color: #ffffff;
      background-color: $oslofjorden-blue;
    }
  }
}

.blockType {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: $spacing-tiny-small;
}

.buttons {
  margin-top: $spacing-xs;
}

.button {
  margin: $spacing-xs;
}

.blockLabel {
  text-align: left;
  margin-top: $spacing-xs;
}

.form {
  display: flex;
  width: 30rem;
  flex-direction: column;
}

.pauseInputWrapper {
  width: 100%;
  margin: $spacing-normal 0;
}

.pauseTitle {
  width: 100%;
  margin-top: $spacing-tiny;
}

.pauseMessage {
  width: 100%;
  height: 320px;
  resize: both;
  display: block;
}