@font-face {
  font-family: 'Museo Sans Rounded';
  font-weight: 700;
  font-style: normal;
  src: url('../../public/css/fonts/Museo-Sans-Rounded/exljbris-MuseoSansRounded-700.otf');
}

@font-face {
  font-family: 'Museo Sans Rounded';
  font-weight: 500;
  font-style: normal;
  src: url('../../public/css/fonts/Museo-Sans-Rounded/exljbris-MuseoSansRounded-500.otf');
}

@font-face {
  font-family: 'Museo Sans Rounded';
  font-weight: 300;
  font-style: normal;
  src: url('../../public/css/fonts/Museo-Sans-Rounded/MuseoSansRounded-300.otf');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 300;
  font-style: normal;
  src: url('../../public/css/fonts/Museo-Sans/MuseoSans-300.otf');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 500;
  font-style: normal;
  src: url('../../public/css/fonts/Museo-Sans/MuseoSans_500.otf');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 700;
  font-style: normal;
  src: url('../../public/css/fonts/Museo-Sans/MuseoSans_700.otf');
}
