@import '../../scss/variables';

.hideContentInputWrapper {
  width: 100%;
  margin: $spacing-normal 0;
}

.hideContentTitle {
  width: 100%;
  margin-top: $spacing-tiny;
}

.hideContentMessage {
  width: 100%;
  height: 300px;
  resize: both;
  display: block;
}

.labelWithCheckbox {
    input {
        margin-right: $spacing-xs;
    }
}
