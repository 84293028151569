@import '../../scss/variables';

.approveProjectButton {
    color: $sognsvann-green;

    &:not(:disabled):hover {
        color: $spleis-green;
    }
}

.approved {
    background-color: $sofienbergparken-green;
    border-radius: $border-radius;
    color: $barcode-black;
    font-size: $font-size-small;
    padding: $spacing-tiny;
    margin: $spacing-xs $spacing-xs $spacing-xs 0;
}
