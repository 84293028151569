@import '../scss/variables';

.projectCategories {
    margin-bottom: $spacing-normal;
}

.projectCategoryListExplanation {
    color: $jernbanetorget-grey;
}

.projectCategoryList {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacing-tiny-small 0;
    margin-left: 0;
    width: 100%;
}

.projectCategory {
    border-radius: 7px;
    color: $white;
    display: inline-block;
    margin: $spacing-xs {
        left: 0;
    }
    max-width: 100%;
    overflow: hidden;
    padding: $spacing-xs;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.projectCategorySuggestion {
    @extend .projectCategory;
    cursor: pointer;
    background-color: $spleis-blue;
}

.projectCategoryOther {
    @extend .projectCategory;
    cursor: pointer;
    background-color: $jernbanetorget-grey;
}

.projectCategoryExplanationHeader {
    @extend .projectCategory;
    background-color: $spleis-blue;
}

.suggestionsLink {
    cursor: pointer;
    text-decoration: underline;
    margin-top: $spacing-small;
}

.modalWrapper {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 19;
}

.modalContainer {
    background-color: $white;
    padding: $spacing-normal;
    border-radius: $border-radius;
    margin: 1rem;
    max-width: 100vw;
    min-width: 400px;
}

.modalButtons {
    text-align: center;
    margin-top: $spacing-normal;
}

.modalButton {
    margin: $spacing-xs;
}

.modalCategory {
    margin-bottom: $spacing-small;
}
