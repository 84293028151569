@import '../scss/variables';

.block {
    display: block;
}

.empty-container {
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    &-text {
        align-self: center;
        font-size: $font-size-large;
        text-align: center;
    }
}

.back-link {
    display: block;
    margin: $spacing-tiny-small $spacing-normal;
    @media (min-width: $size-s) {
        display: none;
    }
}
