@import '../../scss/variables';

.project-alert {
    &-container {
        display: grid;
        grid-template-columns: 1fr auto;
        margin: $spacing-tiny-small;
        width: calc(100% - #{$spacing-tiny-small * 2});
    }

    &-body {
        color: white;
        background: $sykkelvei-red;
        border: 1px solid $sykkelvei-red {
            radius: 5px;
        }
        padding: $spacing-tiny-small;
        div:not(last-child) {
            margin-bottom: $spacing-tiny-small;
        }
    }

    &-actions {
        padding: $spacing-tiny-small;
        div:not(last-child) {
            margin-bottom: $spacing-tiny-small;
        }
    }

}
