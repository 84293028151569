@import '../scss/variables';

.projectInvitationButtons {
    margin-top: $spacing-tiny-small;

    button {
        margin-right: $spacing-tiny-small;
    }
}

.invitationName {
    font-weight: $font-weight-bold;
}

.projectInvitationTableHeader {
    font-weight: $font-weight-bold;
}

.projectInvitationTableCell {
    padding: 0.2em;
}

.projectInvitationTableRow {
    border-bottom: 1px solid $barcode-black;
}

.receiverWarning {
    color: $oslohavn-orange;
}